import { TMFX, TMA, TMSP } from 'CORE__UI/apps/TopMenuApp/core__topMenu';
import styled from 'styled-components';

const TMA_UI = styled(TMA)`
  i {
    margin: 0px 0px 4px 0px;
  }
`;

export { TMFX, TMA_UI as TMA, TMSP };
