import React, { useEffect, useState, useMemo } from 'react';
import { TMFX, TMA, TMSP } from 'UI/apps/TopMenuApp/TopMenu';
import { getIcon } from 'Services/icons/core__icons';
import I from 'UI/globals/Icons';
import PropTypes from 'prop-types';
import project from '../../../../project';
import {
  FSBCustomerSession,
  PubSub,
  PubsubEvents,
} from 'Services/core__services';
/**
 * this component contains TopMenuReact section
 * @param {*} appConfig cms paramters
 **/

export const TopMenuReact = ({ appConfig }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const {
    menuicons,
    menutitles,
    menulinks,
    showhidebasedonauth: showhideItems,
  } = appConfig;
  const icons = menuicons?.split('|') || [],
    titles = menutitles?.split('|') || [],
    links = menulinks?.split('|') || [];

  if (!icons.length || !titles.length || !links.length) {
    return '';
  }

  const showHideBasedOnAuth = useMemo(() => showhideItems?.split(',') || [], [
    showhideItems,
  ]);

  // Filter headers based on auth status
  const filteredIcons = useMemo(() => {
    if (!loggedIn) {
      return icons.filter(key => {
        if (!showHideBasedOnAuth.includes(key)) {
          return key;
        }
      });
    }
    return icons;
  }, [icons, loggedIn, showHideBasedOnAuth]);

  useEffect(() => {
    const updateSession = () => {
      const session = FSBCustomerSession.getSession();
      setLoggedIn(!!session?.accessToken);
    };

    // Listen for session updates
    const sessionUpdated = PubSub.listen(
      PubsubEvents.SESSION_UPDATED,
      updateSession
    );
    const sessionCreated = PubSub.listen(
      PubsubEvents.SESSION_CREATED,
      updateSession
    );

    // Initial session check
    updateSession();

    return () => {
      sessionUpdated?.unsubscribe();
      sessionCreated?.unsubscribe();
    };
  }, []);

  return (
    <TMFX>
      {filteredIcons.map((icon, index) => (
        <TMA
          withBespokeIcons={project.bespokeSvgSportsIcons}
          href={links[index]}
          key={icon}
        >
          {icon?.includes('bespoke') ? (
            <I
              categoryTabs={true}
              marginR={8}
              size={32}
              active={appConfig.requestURL?.includes(icon.split('-')[1])}
              bespokeSvgSportsIcons={project.bespokeSvgSportsIcons}
              category={icon.split('-')[1]}
              iconName={getIcon(icon)}
            />
          ) : (
            <I topMenu={true} marginR={8} size={28} iconName={getIcon(icon)} />
          )}
          <TMSP>{titles[index]}</TMSP>
        </TMA>
      ))}
    </TMFX>
  );
};

TopMenuReact.propTypes = {
  appConfig: PropTypes.object,
  topMenuData: PropTypes.array,
  title: PropTypes.string,
};
