import React from 'react';
import ReactDOM from 'react-dom';

import ScDecoder from 'Services/json/core__decoder';
import createStore from './core__top-menu-store';
import { Provider as ReduxProvider } from 'react-redux';
import { connect } from 'react-redux';
import { TopMenuReact } from './components';

class TopMenuReactApp {
  constructor(properties) {
    Object.assign(this, properties);

    if (this.nodeSSR) return;
    const reduxSelector = document.querySelector(`#redux${this.appSection.id}`);
    reduxSelector
      ? this.start(
          new ScDecoder(JSON.parse(reduxSelector.innerHTML)),
          reduxSelector
        )
      : this.start();
  }

  start(data, reduxSelector) {
    const ReactInstance = this.getReactApp();
    const jsx = (
      <ReduxProvider store={createStore(data || {})}>
        <ReactInstance />
      </ReduxProvider>
    );

    ReactDOM[data ? 'hydrate' : 'render'](jsx, this.appSection);
    if (reduxSelector) reduxSelector.remove();
  }

  getReactApp() {
    const mapStateToProps = () => ({
      appConfig: this.appConfig,
    });

    const mapDispatchToProps = {
      url: this.url,
    };

    TopMenuReact.serverFetch = {
      createStore: createStore,
      fetchData: () => dispatch => {
        return new Promise(resolve => {
          dispatch({ type: 'DO_NOTHING' });
          resolve();
        });
      },
    };

    return connect(mapStateToProps, mapDispatchToProps)(TopMenuReact);
  }
}

export default TopMenuReactApp;
