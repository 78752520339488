import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import getComposeEnhancers from 'Services/redux/core__redux-dev-tools';

export const dataReducer = (state = [], action) => {
  switch (action.type) {
    case 'TOP_MENU_INFO': {
      return {
        ...state,
        topMenuData: action.topMenuData,
        title: action.title,
      };
    }
    default:
      return state;
  }
};
//Combine reducers into a single reducer
export const reducer = combineReducers({
  data: dataReducer,
});

//function to get the data from cms and change according to UI
export const getDataFromCms = data => dispatch => {
  const links = data?.link?.split(',').map(item => {
    return item.trim();
  });
  const names = data?.name?.split(',').map(item => {
    return item.trim();
  });
  const topMenuData = [];
  for (let i = 0; i < names?.length; i++) {
    const name = names[i];
    const link = links[i] || '';
    topMenuData.push({ name, link });
  }
  dispatch({
    type: 'TOP_MENU_INFO',
    title: data?.title,
    topMenuData: topMenuData,
  });
};

const composeEnhancers = getComposeEnhancers({ name: 'top-menu-store' });

export default initialState =>
  createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );
