import styled from 'styled-components';
import { FONT } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

export const TMFX = styled.div`
  display: none;
  @media (max-width: ${BREAKPOINT_S}) {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 8px 4px 0px 4px;
  }
`;
// header main div
export const TMA = styled.a`
  align-items: center;
  color: ${FONT.dark};
  display: flex;
  flex-direction: column;
  margin: 8px auto;
  i {
    margin: ${({ withBespokeIcons }) => !withBespokeIcons && ` 0 0 4px 8px`};
  }
`;

// header main div
export const TMSP = styled.span``;
